import {
  CREATE_POLICY,
  RETRIEVE_POLICIES,
  UPDATE_POLICY,
  DELETE_POLICY,
  RETRIEVE_POLICIES_FAIL,
  RETRIEVE_POLICIES_SUCCESS,
  CREATE_POLICY_SUCCESS,
  UPDATE_POLICY_SUCCESS,
  SEARCH_POLICY,
  SEARCH_POLICY_SUCCESS,
  RETRIEVE_POLICY_SUCCESS,
  FIND_POLICIES_BY_CRYPTO_ID,
  FIND_POLICIES_BY_CRYPTO_ID_SUCCESS,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE
} from '../constants/Policy';

const initialState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  policies: [],
  selectedPolicy: {},
  stats: {},
  cryptoProperties: {},
  sessions: []
};

function policyReducer(state = initialState, action) {
  const { type, payload } = action;

  console.log(action);

  switch (type) {
    case CREATE_POLICY:
      return { ...state, loading: true };

    case CREATE_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data?.message
      };

    case RETRIEVE_POLICIES:
      return { ...state, loading: true };

    case RETRIEVE_POLICIES_SUCCESS:
      return { ...state, policies: payload.data?.policies, loading: false };

    case UPDATE_POLICY:
      console.log("Printing Policys")
      console.log(state.policies)
      return {
        ...state,
        loading: true,
        policies: state.policies.map((policy: any) => {
          if (policy._id === payload.data._id) {
            return {
              ...policy,
              ...payload.data
            };
          } else {
            return policy;
          }
        })
      };

    case UPDATE_POLICY_SUCCESS:
      return { ...state, loading: false, message: payload.message };

    case DELETE_POLICY:
      return { ...state, loading: true };

    case RETRIEVE_POLICIES_FAIL:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    case SEARCH_POLICY:
      return { ...state, loading: true };

    case SEARCH_POLICY_SUCCESS:
      return { ...state, loading: false, policies: payload.data?.policies };

    case RETRIEVE_POLICY_SUCCESS:
      return { ...state, loading: false, selectedPolicy: payload.data?.policy };

    case FIND_POLICIES_BY_CRYPTO_ID:
      return { ...state, loading: true };

    case FIND_POLICIES_BY_CRYPTO_ID_SUCCESS:
      return { ...state, policies: payload.data?.policies, loading: false };

    case UPDATE_STATUS:
      return { ...state, loading: true };

    case UPDATE_STATUS_SUCCESS:
      return { ...state, loading: false, message: payload.data?.message };

    case UPDATE_STATUS_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    default:
      return state;
  }
}

export default policyReducer;