export const RETRIEVE_USEGROUPS = 'RETRIEVE_USEGROUPS';
export const RETRIEVE_USEGROUPS_SUCCESS = 'RETRIEVE_USEGROUPS_SUCCESS';
export const RETRIEVE_USEGROUPS_FAILIURE = 'RETRIEVE_USEGROUPS_FAILIURE';

export const CREATE_USER_GROUP ='CREATE_USER_GROUP';
export const CREATE_USER_GROUP_SUCCESS ='CREATE_USER_GROUP_SUCCESS';

export const UPDATE_USER_GROUP ='UPDATE_USER_GROUP';
export const UPDATE_USER_GROUP_SUCCESS ='UPDATE_USER_GROUP_SUCCESS';

export const DELETE_USER_GROUP ='DELETE_USER_GROUP';
export const DELETE_USER_GROUP_SUCCESS ='DELETE_USER_GROUP_SUCCESS';



//User Group API paths
export const RETRIEVE_USEGROUPS_PATH ="user/usergroup/all";
export const CREATE_USER_GROUP_PATH ="user/usergroup";
export const UPDATE_USER_GROUP_PATH ="user/usergroup/";
export const DELETE_USER_GROUP_PATH ="user/usergroup/bulk/delete";
