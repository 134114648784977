import { take, call, put } from "redux-saga/effects";
import {
  createSession,
  createSessionSuccess,
  deleteSession,
  deleteSessionSuccess,
  retrieveSession,
  retrieveSessions,
  retrieveSessionsFailure,
  retrieveSessionsSuccess,
  retrieveSessionSuccess,
  searchSession,
  searchSessionSuccess,
  updateSession,
  updateSessionSuccess,
  getApplicationSession,
  getApplicationSessionSuccess,
  getApplicationSessionFailure,
} from "../actions/Session";

import {
  CREATE_SESSION_PATH,
  UPDATE_SESSION_PATH,
  DELETE_SESSION_PATH,
  RETRIEVE_SESSIONS_PATH,
  RETRIEVE_SESSION_PATH,
  SEARCH_SESSION_PATH,
  GET_APPLICATION_SESSION_PATH,
} from "../constants/Session";

import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_SESSION_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_SESSION_BACKEND_QA}/${path}`;

export function* retrieveSessionsAPI() {
  while (true) {
    yield take(`${retrieveSessions}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_SESSIONS_PATH),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(retrieveSessionsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveSessionsFailure(err));
  }
}

export function* retrieveSessionAPI() {
  while (true) {
    const action = yield take(`${retrieveSession}`);
    // console.log('node_env', process.env.ENV);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_SESSION_PATH}${action.payload.id}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      // yield call(action.payload.callback, payload || err);
      yield put(retrieveSessionSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveSessionsFailure(err));
  }
}

export function* createSessionAPI() {
  while (true) {
    const action = yield take(`${createSession}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_SESSION_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createSessionSuccess(payload));
      yield put(retrieveSessions({}));
      continue;
    }
    endSession(err);
    yield put(retrieveSessionsFailure(err));
  }
}

export function* deleteSessionAPI() {
  while (true) {
    const action = yield take(`${deleteSession}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_SESSION_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteSessionSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveSessionsFailure(err));
  }
}

export function* updateSessionAPI() {
  while (true) {
    const action = yield take(`${updateSession}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_SESSION_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateSessionSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveSessionsFailure(err));
  }
}

export function* searchSessionAPI() {
  while (true) {
    const action = yield take(`${searchSession}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_SESSION_PATH}${action.payload.path}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(searchSessionSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveSessionsFailure(err));
  }
}

export function* getApplicationSessionAPI() {
  while (true) {
    const action = yield take(`${getApplicationSession}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${GET_APPLICATION_SESSION_PATH}${action.payload.id}`),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(getApplicationSessionSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getApplicationSessionFailure(err));
  }
}
