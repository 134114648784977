import {
  CREATE_POLICY,
  CREATE_POLICY_SUCCESS,
  DELETE_POLICY,
  DELETE_POLICY_SUCCESS,
  RETRIEVE_POLICY,
  RETRIEVE_POLICIES,
  RETRIEVE_POLICIES_FAIL,
  RETRIEVE_POLICIES_SUCCESS,
  UPDATE_POLICY,
  UPDATE_POLICY_SUCCESS,
  SEARCH_POLICY_SUCCESS,
  SEARCH_POLICY,
  RETRIEVE_POLICY_SUCCESS,
  FIND_POLICIES_BY_CRYPTO_ID,
  FIND_POLICIES_BY_CRYPTO_ID_SUCCESS,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE
} from '../constants/Policy';

import { createAction } from 'redux-act';

// Policy
export const createPolicy = createAction<any | undefined>(CREATE_POLICY);
export const createPolicySuccess = createAction<any | undefined>(CREATE_POLICY_SUCCESS);
export const deletePolicy = createAction<any | undefined>(DELETE_POLICY);
export const deletePolicySuccess = createAction<any | undefined>(DELETE_POLICY_SUCCESS);
export const retrievePolicy = createAction<any | undefined>(RETRIEVE_POLICY);
export const retrievePolicySuccess = createAction<any | undefined>(RETRIEVE_POLICY_SUCCESS);
export const retrievePolicies = createAction<any | undefined>(RETRIEVE_POLICIES);
export const retrievePoliciesSuccess = createAction<any | undefined>(RETRIEVE_POLICIES_SUCCESS);
export const retrievePoliciesFailure = createAction<any | undefined>(RETRIEVE_POLICIES_FAIL);
export const updatePolicy = createAction<any | undefined>(UPDATE_POLICY);
export const updatePolicySuccess = createAction<any | undefined>(UPDATE_POLICY_SUCCESS);
export const searchPolicy = createAction<any | undefined>(SEARCH_POLICY);
export const searchPolicySuccess = createAction<any | undefined>(SEARCH_POLICY_SUCCESS);
export const findPoliciesByCryptoId = createAction<any | undefined>(FIND_POLICIES_BY_CRYPTO_ID);
export const findPoliciesByCryptoIdSuccess = createAction<any | undefined>(FIND_POLICIES_BY_CRYPTO_ID_SUCCESS);
export const updateStatus = createAction<any | undefined>(UPDATE_STATUS);
export const updateStatusSuccess = createAction<any | undefined>(UPDATE_STATUS_SUCCESS);
export const updateStatusFailure = createAction<any | undefined>(UPDATE_STATUS_FAILURE);