export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const RETRIEVE_SESSION = 'RETRIEVE_SESSION';
export const RETRIEVE_SESSION_SUCCESS = 'RETRIEVE_SESSION_SUCCESS';
export const RETRIEVE_SESSIONS = 'RETRIEVE_SESSIONS';
export const DELETE_ALL_SESSIONS = 'DELETE_ALL_SESSIONS';
export const RETRIEVE_SESSIONS_SUCCESS = 'RETRIEVE_SESSIONS_SUCCESS';
export const RETRIEVE_SESSIONS_FAIL = 'RETRIEVE_SESSIONS_FAIL';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const SEARCH_SESSION = 'SEARCH_SESSION';
export const SEARCH_SESSION_SUCCESS = 'SEARCH_SESSION_SUCCESS';

export const GET_APPLICATION_SESSION = 'GET_APPLICATION_SESSION';
export const GET_APPLICATION_SESSION_SUCCESS = 'GET_APPLICATION_SESSION_SUCCESS';
export const GET_APPLICATION_SESSION_FAILURE = 'GET_APPLICATION_SESSION_FAILURE';

// SESSION API Paths
export const CREATE_SESSION_PATH = 'session';
export const RETRIEVE_SESSIONS_PATH = 'session/all';
export const RETRIEVE_SESSION_PATH = 'session/';
export const DELETE_SESSION_PATH = 'session/bulk/delete';
export const SEARCH_SESSION_PATH = 'session/advance/search';
export const UPDATE_SESSION_PATH = 'session/';

export const GET_APPLICATION_SESSION_PATH = 'session/find/';
