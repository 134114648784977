import { combineReducers } from 'redux';
import Auth from './auth';
import Theme from './Theme';
import Device from './Device';
import Category from './Category';
import Crypto from './Crypto';
import Eligibility from './Eligibility';
import Provisioning from './Provisioning';
import userGroup from './userGroups'
import User from './Users'
import Permission from './permission';
import Application from './Application';
import Session from './Session';
import Policy from './Policy';

const reducers = combineReducers({
  auth: Auth,
  theme: Theme,
  device: Device,
  users : User,
  permission : Permission,
  usergroup : userGroup,
  category: Category,
  crypto: Crypto,
  eligibility: Eligibility,
  provisioning: Provisioning,
  application: Application,
  session: Session,
  policy: Policy
});

export default reducers;
