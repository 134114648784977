import {
  DashboardOutlined,
  ApiOutlined,
  FileProtectOutlined,
  LockOutlined,
  UserOutlined,
  TeamOutlined,
  FileTextOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "./appConfig";


const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },

  {
    key: "application",
    path: `${APP_PREFIX_PATH}/application`,
    title: "Application Management",
    icon: ApiOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "crypto",
    path: `${APP_PREFIX_PATH}/crypto`,
    title: "Provider Management",
    icon: LockOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: "policies",
    path: `${APP_PREFIX_PATH}/policies`,
    title: "Policy Management",
    icon: FileProtectOutlined,
    breadcrumb: true,
    submenu: [],
  },

  {
    key: "users",
    // path: `${APP_PREFIX_PATH}/users`,
    title: "User Managment",
    icon: UserOutlined,
    breadcrumb: true,

    submenu: [
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/users`,
        title: "Users",
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "userGroups",
        path: `${APP_PREFIX_PATH}/userGroups`,
        title: "Groups",
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "permissions",
        path: `${APP_PREFIX_PATH}/permissions`,
        title: "Permissions",
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },

  {
    key: "tools",
    path: `${APP_PREFIX_PATH}/tools`,
    title: "Tools",
    icon: ToolOutlined,
    breadcrumb: true,
    submenu: [],
  },

  // {
  //   key: 'UserManagment',
  //   path: `${APP_PREFIX_PATH}/userManagement`,
  //   title: 'User Management',
  //   icon: UserOutlined,
  //   breadcrumb: true,
  //   submenu: []
  // },
  // {
  //   key: 'policies',
  //   path: `${APP_PREFIX_PATH}/policies`,
  //   title: 'Eligibility Management',
  //   icon: FileProtectOutlined,
  //   breadcrumb: true,
  //   submenu: []
  // },
  // {
  //   key: 'provisioning',
  //   path: `${APP_PREFIX_PATH}/provisioning`,
  //   title: 'Provisioning',
  //   icon: LinkOutlined,
  //   breadcrumb: true,
  //   submenu: []
  // },

  // {

  //   key: 'users',
  //   // path: `${APP_PREFIX_PATH}/users`,
  //   title: 'User Managment',
  //   icon: UserOutlined,
  //   breadcrumb: true,

  //   submenu: [
  //     {
  //       key: 'users',
  //       path: `${APP_PREFIX_PATH}/users`,
  //       title: 'Users',
  //       icon: UserOutlined,
  //       breadcrumb: true,
  //       submenu: [

  //       ],

  //     },
  //     {
  //       key: 'userGroups',
  //       path: `${APP_PREFIX_PATH}/userGroups`,
  //       title: 'Groups',
  //       icon: TeamOutlined,
  //       breadcrumb: true,
  //       submenu: [

  //       ]
  //     },
  //     {
  //       key: 'permissions',
  //       path: `${APP_PREFIX_PATH}/permissions`,
  //       title: 'Permissions',
  //       icon: FileTextOutlined,
  //       breadcrumb: true,
  //       submenu: [

  //       ]
  //     }

  //   ],

  // },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
