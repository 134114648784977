import {
  CHANGE_ELIGIBILITY_STATUS,
  CHANGE_ELIGIBILITY_STATUS_FAILURE,
  CHANGE_ELIGIBILITY_STATUS_SUCCESS,
  CREATE_ELIGIBILITY_RULE,
  CREATE_ELIGIBILITY_RULE_SUCCESS,
  DELETE_ELIGIBILITY_RULES,
  DELETE_ELIGIBILITY_RULE_SUCCESS,
  GET_ELIGIBILITY_STATS,
  GET_ELIGIBILITY_STATS_FAILURE,
  GET_ELIGIBILITY_STATS_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULE,
  RETRIEVE_ELIGIBILITY_RULES,
  RETRIEVE_ELIGIBILITY_RULES_FAIL,
  RETRIEVE_ELIGIBILITY_RULES_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULE_SUCCESS,
  SEARCH_ELIGIBILITY_RULE,
  SEARCH_ELIGIBILITY_RULE_SUCCESS,
  UPDATE_ELIGIBILITY_RULE,
  UPDATE_ELIGIBILITY_RULE_SUCCESS
} from '../constants/Eligibility';

const initialState = {
  loading: false,
  error: false,
  total: 0,
  rules: [],
  selectedRule: {},
  description: [],
  stats: {}
};

function eligibilityReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ELIGIBILITY_RULE:
      return { ...state, loading: true };

    case CREATE_ELIGIBILITY_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        rules: [...state.rules, payload.data]
      };

    case RETRIEVE_ELIGIBILITY_RULES:
      return { ...state, loading: true };

    case RETRIEVE_ELIGIBILITY_RULES_FAIL:
      return { ...state, loading: false, error: true };

    case RETRIEVE_ELIGIBILITY_RULES_SUCCESS:
      return { ...state, loading: false, rules: payload.data?.rules };

    case UPDATE_ELIGIBILITY_RULE:
      return {
        ...state,
        loading: true,
        rules: state.rules.map((eligibility: any) => {
          if (eligibility._id === payload.data._id) {
            return {
              ...eligibility,
              ...payload.data
            };
          } else {
            return eligibility;
          }
        })
      };

    case UPDATE_ELIGIBILITY_RULE_SUCCESS:
      return { ...state, loading: true };

    case DELETE_ELIGIBILITY_RULES:
      return {
        ...state,
        loading: true,
        rules: state.rules.map((eligibility: any, index) => {
          if (eligibility._id === payload.id) {
            return {
              ...state.rules.splice(index, 1)
            };
          } else {
            return eligibility;
          }
        })
      };

    case DELETE_ELIGIBILITY_RULE_SUCCESS:
      return { ...state, loading: false, description: payload.data?.description };

    case SEARCH_ELIGIBILITY_RULE:
      return { ...state, loading: true };

    case SEARCH_ELIGIBILITY_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        rules: payload.data?.rules,
        total: payload.data?.total
      };

    case RETRIEVE_ELIGIBILITY_RULE:
      return { ...state, loading: true };

    case RETRIEVE_ELIGIBILITY_RULE_SUCCESS:
      return { ...state, loading: false, selectedRule: payload.data?.rule };

    case GET_ELIGIBILITY_STATS:
      return { ...state, loading: true };

    case GET_ELIGIBILITY_STATS_SUCCESS:
      return { ...state, loading: false, stats: payload };

    case GET_ELIGIBILITY_STATS_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    case CHANGE_ELIGIBILITY_STATUS:
      return { ...state, loading: true };

    case CHANGE_ELIGIBILITY_STATUS_SUCCESS:
      return { ...state, loading: false };

    case CHANGE_ELIGIBILITY_STATUS_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };
    default:
      return state;
  }
}

export default eligibilityReducer;
