import {
    RETRIEVE_PERMISSIONS,
    RETRIEVE_PERMISSIONS_SUCCESS,
    RETRIEVE_PERMISSIONS_FAILIURE,
    CREATE_PERMISSIONS,
    CREATE_PERMISSIONS_SUCCESS,
    CREATE_PERMISSIONS_FAILIURE,
    UPDATE_PERMISSIONS,
    UPDATE_PERMISSIONS_SUCCESS,
    UPDATE_PERMISSIONS_FAILIURE,
    DELETE_PERMISSIONS,
    DELETE_PERMISSIONS_SUCCESS,
    DELETE_PERMISSIONS_FAILIURE



} from '../constants/Permissions';
import { createAction } from 'redux-act';

export const retrivePermissions = createAction<any | undefined>(RETRIEVE_PERMISSIONS);
export const retrivePermissionsSuccess = createAction<any | undefined>(RETRIEVE_PERMISSIONS_SUCCESS);
export const retrivePermissionsFailiure = createAction<any | undefined>(RETRIEVE_PERMISSIONS_FAILIURE);

export const createPermission = createAction<any | undefined>(CREATE_PERMISSIONS);
export const createPermissionSucess = createAction<any | undefined>(CREATE_PERMISSIONS_SUCCESS);
export const createPermissionFailiure = createAction<any | undefined>(CREATE_PERMISSIONS_FAILIURE);

export const updatePermission = createAction<any | undefined>(UPDATE_PERMISSIONS);
export const updatePermissionSuccess = createAction<any | undefined>(UPDATE_PERMISSIONS_SUCCESS);
export const updatePermissionFailiure = createAction<any | undefined>(UPDATE_PERMISSIONS_FAILIURE);

export const deletePermission = createAction<any | undefined>(DELETE_PERMISSIONS);
export const deletePermissionSuccess = createAction<any | undefined>(DELETE_PERMISSIONS_SUCCESS);
export const deletePermissionFailiure = createAction<any | undefined>(DELETE_PERMISSIONS_FAILIURE);