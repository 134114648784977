import {
  CREATE_CRYPTO_ASSET,
  CREATE_CRYPTO_ASSET_SUCCESS,
  DELETE_CRYPTO_ASSET,
  DELETE_CRYPTO_ASSETS,
  DELETE_CRYPTO_ASSET_SUCCESS,
  RETRIEVE_CRYPTO_ASSETS,
  RETRIEVE_CRYPTO_ASSETS_FAIL,
  RETRIEVE_CRYPTO_ASSETS_SUCCESS,
  SEARCH_CRYPTO_ASSET,
  SEARCH_CRYPTO_ASSET_SUCCESS,
  UPDATE_CRYPTO_ASSET,
  UPDATE_CRYPTO_ASSET_SUCCESS,
  FILE_UPLOAD_INIT,
  RETRIEVE_CRYPTO_ASSET,
  RETRIEVE_CRYPTO_ASSET_SUCCESS,
  GET_CRYPTO_STATS,
  GET_CRYPTO_STATS_SUCCESS,
  GET_CRYPTO_STATS_FAILURE,
  DOWNLOAD_CRYPTO_ASSET,
  GET_AUDIT_LOGS,
  GET_AUDIT_LOGS_SUCCESS,
  GET_AUDIT_LOGS_FAILURE,
  GET_CRYPTO_IDS,
  GET_CRYPTO_IDS_SUCCESS,
  GET_CRYPTO_IDS_FAILURE
} from '../constants/Crypto';

import { createAction } from 'redux-act';

// Crypto
export const createCrypto = createAction<any | undefined>(CREATE_CRYPTO_ASSET);
export const createCryptoSuccess = createAction<any | undefined>(CREATE_CRYPTO_ASSET_SUCCESS);
export const updateCrypto = createAction<any | undefined>(UPDATE_CRYPTO_ASSET);
export const updateCryptoSuccess = createAction<any | undefined>(UPDATE_CRYPTO_ASSET_SUCCESS);
export const deleteCrypto = createAction<any | undefined>(DELETE_CRYPTO_ASSET);
export const deleteCryptoAssets = createAction<any | undefined>(DELETE_CRYPTO_ASSETS);
export const deleteCryptoSuccess = createAction<any | undefined>(DELETE_CRYPTO_ASSET_SUCCESS);

export const retrieveCrypto = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS);
export const retrieveCryptoSuccess = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS_SUCCESS);
export const retrieveCryptoAsset = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSET);
export const retrieveCryptoAssetSuccess = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSET_SUCCESS);
export const retrieveCryptoFailure = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS_FAIL);

export const searchCrypto = createAction<any | undefined>(SEARCH_CRYPTO_ASSET);
export const searchCryptoSuccess = createAction<any | undefined>(SEARCH_CRYPTO_ASSET_SUCCESS);

export const fileUploadInit = createAction<any | undefined>(FILE_UPLOAD_INIT);
export const downloadCryptoAsset = createAction<any | undefined>(DOWNLOAD_CRYPTO_ASSET);

export const getCryptoStats = createAction<any | undefined>(GET_CRYPTO_STATS);
export const getCryptoStatsSuccess = createAction<any | undefined>(GET_CRYPTO_STATS_SUCCESS);
export const getCryptoStatsFailure = createAction<any | undefined>(GET_CRYPTO_STATS_FAILURE);

export const getAuditLogs = createAction<any | undefined>(GET_AUDIT_LOGS);
export const getAuditLogsSuccess = createAction<any | undefined>(GET_AUDIT_LOGS_SUCCESS);
export const getAuditLogsFailure = createAction<any | undefined>(GET_AUDIT_LOGS_FAILURE);

export const getCryptoIds = createAction<any | undefined>(GET_CRYPTO_IDS);
export const getCryptoIdsSuccess = createAction<any | undefined>(GET_CRYPTO_IDS_SUCCESS);
export const getCryptoIdsFailure = createAction<any | undefined>(GET_CRYPTO_IDS_FAILURE);