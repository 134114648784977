export const CREATE_CRYPTO_ASSET = 'CREATE_CRYPTO_ASSET';
export const CREATE_CRYPTO_ASSET_SUCCESS = 'CREATE_CRYPTO_ASSET_SUCCESS';
export const UPDATE_CRYPTO_ASSET = 'UPDATE_CRYPTO_ASSET';
export const UPDATE_CRYPTO_ASSET_SUCCESS = 'UPDATE_CRYPTO_ASSET_SUCCESS';
export const RETRIEVE_CRYPTO_ASSETS_FAIL = 'RETRIEVE_CRYPTO_ASSETS_FAIL';
export const DELETE_CRYPTO_ASSET = 'DELETE_CRYPTO_ASSET';
export const DELETE_CRYPTO_ASSETS = 'DELETE_CRYPTO_ASSETS';
export const DELETE_CRYPTO_ASSET_SUCCESS = 'DELETE_CRYPTO_ASSET_SUCCESS';
export const SEARCH_CRYPTO_ASSET = 'SEARCH_CRYPTO_ASSET';
export const SEARCH_CRYPTO_ASSET_SUCCESS = 'SEARCH_CRYPTO_ASSET_SUCCESS';

export const RETRIEVE_CRYPTO_ASSET = 'RETRIEVE_CRYPTO_ASSET';
export const RETRIEVE_CRYPTO_ASSET_SUCCESS = 'RETRIEVE_CRYPTO_ASSET_SUCCESS';
export const RETRIEVE_CRYPTO_ASSETS = 'RETRIEVE_CRYPTO_ASSETS';
export const RETRIEVE_CRYPTO_ASSETS_SUCCESS = 'RETRIEVE_CRYPTO_ASSETS_SUCCESS';

export const FILE_UPLOAD_INIT = 'FILE_UPLOAD_INIT';
export const CRYPTO_BINARY_UPLOAD = 'CRYPTO_BINARY_UPLOAD';
export const DOWNLOAD_CRYPTO_ASSET = 'DOWNLOAD_CRYPTO_ASSET';

export const GET_CRYPTO_STATS = 'GET_CRYPTO_STATS';
export const GET_CRYPTO_STATS_SUCCESS = 'GET_CRYPTO_STATS_SUCCESS';
export const GET_CRYPTO_STATS_FAILURE = 'GET_CRYPTO_STATS_FAILURE';

export const GET_AUDIT_LOGS = 'GET_AUDIT_LOGS';
export const GET_AUDIT_LOGS_SUCCESS = 'GET_AUDIT_LOGS_SUCCESS';
export const GET_AUDIT_LOGS_FAILURE = 'GET_AUDIT_LOGS_FAILURE';

export const GET_CRYPTO_IDS = 'GET_CRYPTO_IDS';
export const GET_CRYPTO_IDS_SUCCESS = 'GET_CRYPTO_IDS_SUCCESS';
export const GET_CRYPTO_IDS_FAILURE = 'GET_CRYPTO_IDS_FAILURE';

export const RETRIEVE_CRYPTO_ASSET_PATH = 'crypto/';
export const RETRIEVE_CRYPTO_ASSETS_PATH = 'crypto/all';
export const CREATE_CRYPTO_ASSET_PATH = 'crypto';
export const UPDATE_CRYPTO_ASSET_PATH = 'crypto/';
export const DELETE_CRYPTO_ASSET_PATH = 'crypto/';
export const DELETE_CRYPTO_ASSETS_PATH = 'crypto/bulk/delete';
export const SEARCH_CRYPTO_ASSET_PATH = 'crypto/advance/search';
export const FILE_UPLOAD_INIT_PATH = 'common/signedurl';
export const GET_CRYPTO_STATS_PATH = 'crypto/statistic/summary';

export const AUDIT_LOGS_PATH = 'crypto/';
export const GET_AUDIT_LOGS_PATH = '/auditTrail';

export const GET_ALL_CRYPTO_IDS_PATH = 'crypto/all/ids';
