import { take, call, put } from "redux-saga/effects";
import {
  createCategory,
  createCategorySuccess,
  deleteCategory,
  deleteCategorySuccess,
  retrieveCategories,
  retrieveCategoriesFailure,
  retrieveCategoriesSuccess,
  updateCategory,
  updateCategorySuccess,
} from "../actions/Category";
import {
  CREATE_CATEGORY_PATH,
  DELETE_CATEGORY_PATH,
  RETRIEVE_CATEGORIES_PATH,
  UPDATE_CATEGORY_PATH,
} from "../constants/Category";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_DEVICE_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_DEVICE_BACKEND_QA}/${path}`;

export function* retrieveCategoriesAPI() {
  while (true) {
    yield take(`${retrieveCategories}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_CATEGORIES_PATH),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(retrieveCategoriesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCategoriesFailure(err));
  }
}

export function* createCategoryAPI() {
  while (true) {
    const action = yield take(`${createCategory}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_CATEGORY_PATH),
      action.payload.data,
      "POST",
      {}
    );

    if (payload && !err) {
      yield call(action.payload.callback, payload);
      yield put(createCategorySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCategoriesFailure(err));
  }
}

export function* deleteCategoryAPI() {
  while (true) {
    const action = yield take(`${deleteCategory}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${DELETE_CATEGORY_PATH}${action.payload.id}`),
      "",
      "DELETE",
      {}
    );

    if (payload && !err) {
      yield call(action.payload.callback, payload);
      yield put(deleteCategorySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCategoriesFailure(err));
  }
}

export function* updateCategoryAPI() {
  while (true) {
    const action = yield take(`${updateCategory}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_CATEGORY_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT",
      {}
    );

    if (payload && !err) {
      yield call(action.payload.callback, payload);
      yield put(updateCategorySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCategoriesFailure(err));
  }
}
