export const CREATE_POLICY = 'CREATE_POLICY';
export const CREATE_POLICY_SUCCESS = 'CREATE_POLICY_SUCCESS';
export const DELETE_POLICY = 'DELETE_POLICY';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const RETRIEVE_POLICY = 'RETRIEVE_POLICY';
export const RETRIEVE_POLICY_SUCCESS = 'RETRIEVE_POLICY_SUCCESS';
export const RETRIEVE_POLICIES = 'RETRIEVE_POLICIES';
export const DELETE_ALL_POLICIES = 'DELETE_ALL_POLICIES';
export const RETRIEVE_POLICIES_SUCCESS = 'RETRIEVE_POLICIES_SUCCESS';
export const RETRIEVE_POLICIES_FAIL = 'RETRIEVE_POLICIES_FAIL';
export const UPDATE_POLICY = 'UPDATE_POLICY';
export const UPDATE_POLICY_SUCCESS = 'UPDATE_POLICY_SUCCESS';
export const SEARCH_POLICY = 'SEARCH_POLICY';
export const SEARCH_POLICY_SUCCESS = 'SEARCH_POLICY_SUCCESS';
export const FIND_POLICIES_BY_CRYPTO_ID = 'FIND_POLICIES_BY_CRYPTO_ID';
export const FIND_POLICIES_BY_CRYPTO_ID_SUCCESS = 'FIND_POLICIES_BY_CRYPTO_ID_SUCCESS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

// POLICY API Paths
export const CREATE_POLICY_PATH = 'policy';
export const RETRIEVE_POLICIES_PATH = 'policy/all';
export const RETRIEVE_POLICY_PATH = 'policy/';
export const DELETE_POLICY_PATH = 'policy/bulk/delete';
export const SEARCH_POLICY_PATH = 'policy/advance/search';
export const UPDATE_POLICY_PATH = 'policy/';
export const FIND_POLICIES_BY_CRYPTO_ID_PATH = "policy/find/cryptoId/"
export const UPDATE_STATUS_PATH = 'policy/update/status/';