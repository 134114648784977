export const RETRIEVE_PERMISSIONS = 'RETRIEVE_PERMISSIONS';
export const RETRIEVE_PERMISSIONS_SUCCESS = 'RETRIEVE_PERMISSIONS_SUCCESS';
export const RETRIEVE_PERMISSIONS_FAILIURE = 'RETRIEVE_PERMISSIONS_FAILIURE';

export const CREATE_PERMISSIONS = 'CREATE_PERMISSIONS';
export const CREATE_PERMISSIONS_SUCCESS = 'CREATE_PERMISSIONS_SUCCESS';
export const CREATE_PERMISSIONS_FAILIURE = 'CREATE_PERMISSIONS_FAILIURE';

export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
export const UPDATE_PERMISSIONS_SUCCESS = 'UPDATE_PERMISSIONS_SUCCESS';
export const UPDATE_PERMISSIONS_FAILIURE = 'UPDATE_PERMISSIONS_FAILIURE';

export const DELETE_PERMISSIONS = 'DELETE_PERMISSIONS';
export const DELETE_PERMISSIONS_SUCCESS = 'DELETE_PERMISSIONS_SUCCESS';
export const DELETE_PERMISSIONS_FAILIURE = 'DELETE_PERMISSIONS_FAILIURE';


//User Permission API paths
export const RETRIEVE_PERMISSIONS_PATH ="user/userpermission/all";
export const CREATE_PERMISSIONS_PATH ="user/userpermission";
export const UPDATE_PERMISSIONS_PATH ="user/userpermission/";
export const DELETE_PERMISSIONS_PATH ="user/userpermission/bulk/delete"; 
