export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const RETRIEVE_APPLICATION = 'RETRIEVE_APPLICATION';
export const RETRIEVE_APPLICATION_SUCCESS = 'RETRIEVE_APPLICATION_SUCCESS';
export const RETRIEVE_APPLICATIONS = 'RETRIEVE_APPLICATIONS';
export const DELETE_ALL_APPLICATIONS = 'DELETE_ALL_APPLICATIONS';
export const RETRIEVE_APPLICATIONS_SUCCESS = 'RETRIEVE_APPLICATIONS_SUCCESS';
export const RETRIEVE_APPLICATIONS_FAIL = 'RETRIEVE_APPLICATIONS_FAIL';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const SEARCH_APPLICATION = 'SEARCH_APPLICATION';
export const SEARCH_APPLICATION_SUCCESS = 'SEARCH_APPLICATION_SUCCESS';

export const UPDATE_APPLICATION_ALGO_MAP = 'UPDATE_ALGO_MAP';
export const UPDATE_APPLICATION_ALGO_MAP_SUCCESS = 'UPDATE_APPLICATION_ALGO_MAP_SUCCESS';

export const GET_APPLICATION_STATS = 'GET_APPLICATION_STATS';
export const GET_APPLICATION_STATS_SUCCESS = 'GET_APPLICATION_STATS_SUCCESS';
export const GET_APPLICATION_STATS_FAILURE = 'GET_APPLICATION_STATS_FAILURE';

export const GET_APPLICATION_TIMELINE = 'GET_APPLICATION_TIMELINE';
export const GET_APPLICATION_TIMELINE_SUCCESS = 'GET_APPLICATION_TIMELINE_SUCCESS';
export const GET_APPLICATION_TIMELINE_FAILURE = 'GET_APPLICATION_TIMELINE_FAILURE';

export const GET_APPLICATION_CRYPTO_PROPERTIES = 'GET_APPLICATION_CRYPTO_PROPERTIES';
export const GET_APPLICATION_CRYPTO_PROPERTIES_SUCCESS = 'GET_APPLICATION_CRYPTO_PROPERTIES_SUCCESS';
export const GET_APPLICATION_CRYPTO_PROPERTIES_FAILURE = 'GET_APPLICATION_CRYPTO_PROPERTIES_FAILURE';

// APPLICATION API Paths
export const CREATE_APPLICATION_PATH = 'application';
export const RETRIEVE_APPLICATIONS_PATH = 'application/all';
export const RETRIEVE_APPLICATION_PATH = 'application/';
export const DELETE_APPLICATION_PATH = 'application/bulk/delete';
export const SEARCH_APPLICATION_PATH = 'application/advance/search';
export const UPDATE_APPLICATION_PATH = 'application/';
export const GET_APPLICATION_STATS_PATH = 'application/statistic/summary';
export const GET_APPLICATION_TIMELINE_PATH = 'application/application/workflow/';

export const APPLICATION_ALGO_CHANGE_PATH = 'application/algo-change';

export const CRYPTO_PROPERTIES_PATH = 'application/';
export const CRYPTO_PROPERTY = '/crypto-properties';
