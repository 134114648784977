const uploadFile = req => {
  let reqUrl = req.url;
  const reqObj = { method: req.type };

  if (req.type === 'POST' || req.type === 'PUT') {
    if (req.fileName) {
      reqObj.body = req.data.originFileObj;
      reqObj.headers = { 'Content-Type': 'text/csv' };
    } else {
      reqObj.body = req.data.originFileObj;
    }
  }

  return fetch(reqUrl, reqObj)
    .then(res => ({ res }))
    .catch(error => ({ error }));
};

export default uploadFile;
