import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './containers';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { THEME_CONFIG } from './configs/appConfig';
import './assets/less/index.less';
import './assets/less/dark-theme.less';
import './assets/less/light-theme.less';
import './assets/less/styles/styles.less';
import { fetchLoginState } from './redux/actions/Auth';

const themes = {
  dark: `./assets/css/dark-theme.css`,
  light: `./assets/css/light-theme.css`
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname } = document.location;

    dispatch(fetchLoginState({ pathname: pathname }));
  }, [dispatch]);

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point">
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
