import {
  CREATE_SESSION,
  RETRIEVE_SESSIONS,
  UPDATE_SESSION,
  DELETE_SESSION,
  RETRIEVE_SESSIONS_FAIL,
  RETRIEVE_SESSIONS_SUCCESS,
  CREATE_SESSION_SUCCESS,
  UPDATE_SESSION_SUCCESS,
  SEARCH_SESSION,
  SEARCH_SESSION_SUCCESS,
  RETRIEVE_SESSION_SUCCESS,
  GET_APPLICATION_SESSION,
  GET_APPLICATION_SESSION_SUCCESS,
  GET_APPLICATION_SESSION_FAILURE
} from '../constants/Session';

const initialState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  sessions: [],
  selectedSession: {}
};

function sessionReducer(state = initialState, action) {
  const { type, payload } = action;

  console.log(action);

  switch (type) {
    case CREATE_SESSION:
      return { ...state, loading: true };

    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data?.message
      };

    case RETRIEVE_SESSIONS:
      return { ...state, loading: true };

    case RETRIEVE_SESSIONS_SUCCESS:
      return { ...state, sessions: payload.data?.session, loading: false };

    case UPDATE_SESSION:
      console.log("Printing Applications")
      console.log(state.sessions)
      return {
        ...state,
        loading: true,
        sessions: state.sessions.map((session: any) => {
          if (session._id === payload.data._id) {
            return {
              ...session,
              ...payload.data
            };
          } else {
            return session;
          }
        })
      };

    case UPDATE_SESSION_SUCCESS:
      return { ...state, loading: false, message: payload.message };

    case DELETE_SESSION:
      return { ...state, loading: true };

    case RETRIEVE_SESSIONS_FAIL:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    case SEARCH_SESSION:
      return { ...state, loading: true };

    case SEARCH_SESSION_SUCCESS:
      return { ...state, loading: false, sessions: payload.data?.sessions };

    case RETRIEVE_SESSION_SUCCESS:
      return { ...state, loading: false, selectedApplication: payload.data?.session };

    case GET_APPLICATION_SESSION:
      return { ...state, loading: true };

    case GET_APPLICATION_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        sessions: payload.data?.sessions
      };

    case GET_APPLICATION_SESSION_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    default:
      return state;
  }
}

export default sessionReducer;