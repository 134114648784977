import { take, call, put } from "redux-saga/effects";
import {
  retrivePermissions,
  retrivePermissionsSuccess,
  retrivePermissionsFailiure,
  createPermission,
  createPermissionSucess,
  updatePermission,
  updatePermissionSuccess,
  deletePermission,
  deletePermissionSuccess,
  deletePermissionFailiure,
} from "../actions/Permissions";
import {
  RETRIEVE_PERMISSIONS_PATH,
  CREATE_PERMISSIONS_PATH,
  UPDATE_PERMISSIONS_PATH,
  DELETE_PERMISSIONS_PATH,
} from "../constants/Permissions";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_USERS_BACKEND_QA}/${path}`
    : `${process.env.REACT_APP_USERS_BACKEND_QA}/${path}`;

export function* retrievePermissionsAPI() {
  while (true) {
    yield take(`${retrivePermissions}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_PERMISSIONS_PATH),
      "",
      "GET",
      {}
    );

    if (payload && !err) {
      yield put(retrivePermissionsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrivePermissionsFailiure(err));
  }
}

export function* createPermissionApi() {
  while (true) {
    const action = yield take(`${createPermission}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_PERMISSIONS_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createPermissionSucess(payload));
      yield put(retrivePermissions({}));
      continue;
    }
    endSession(err);
  }
}

export function* updatePermissionAPI() {
  while (true) {
    const action = yield take(`${updatePermission}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_PERMISSIONS_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updatePermissionSuccess(payload));
      // yield put(retrivePermissions({}));
      continue;
    }
    endSession(err);
    yield put(retrivePermissionsFailiure(err));
  }
}

export function* deletePermissionAPI() {
  while (true) {
    const action = yield take(`${deletePermission}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_PERMISSIONS_PATH),
      action.payload.data,
      "POST",
      {}
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deletePermissionSuccess(payload));
      yield put(retrivePermissions({}));
      continue;
    }
    endSession(err);
    yield put(deletePermissionFailiure(err));
  }
}
