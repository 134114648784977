

export const logOut = () => {
    localStorage.clear();
};


export const getAccessToken = (): string => {
    try {
        const token = localStorage.getItem('tauJWT');
        if (token) {
            return token;
        } else {
            return ''
        }
    } catch (exception) {
        return '';
    }
};
