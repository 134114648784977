export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const RETRIEVE_USERS_SUCCESS = "RETRIEVE_USERS_SUCCESS";
export const RETRIEVE_USERS_FAILURE = "RETRIEVE_USERS_FAILURE";
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const DELETE_USER_FAILIURE = 'DELETE_USER_FAILIURE';



//User API paths

export const CREATE_USER_PATH = 'user';
export const RETRIEVE_USERS_PATH = "user/all";
export const SEARCH_USERS_PATH = "user/usergroup";
export const DELETE_USER_PATH = 'user/bulk/delete';
export const UPDATE_USER_PATH = 'user/';

