import {
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  RETRIEVE_SESSION,
  RETRIEVE_SESSIONS,
  RETRIEVE_SESSIONS_FAIL,
  RETRIEVE_SESSIONS_SUCCESS,
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  SEARCH_SESSION_SUCCESS,
  SEARCH_SESSION,
  RETRIEVE_SESSION_SUCCESS,
  GET_APPLICATION_SESSION,
  GET_APPLICATION_SESSION_SUCCESS,
  GET_APPLICATION_SESSION_FAILURE,
} from '../constants/Session';

import { createAction } from 'redux-act';

// Session
export const createSession = createAction<any | undefined>(CREATE_SESSION);
export const createSessionSuccess = createAction<any | undefined>(CREATE_SESSION_SUCCESS);
export const deleteSession = createAction<any | undefined>(DELETE_SESSION);
export const deleteSessionSuccess = createAction<any | undefined>(DELETE_SESSION_SUCCESS);
export const retrieveSession = createAction<any | undefined>(RETRIEVE_SESSION);
export const retrieveSessionSuccess = createAction<any | undefined>(RETRIEVE_SESSION_SUCCESS);
export const retrieveSessions = createAction<any | undefined>(RETRIEVE_SESSIONS);
export const retrieveSessionsSuccess = createAction<any | undefined>(RETRIEVE_SESSIONS_SUCCESS);
export const retrieveSessionsFailure = createAction<any | undefined>(RETRIEVE_SESSIONS_FAIL);
export const updateSession = createAction<any | undefined>(UPDATE_SESSION);
export const updateSessionSuccess = createAction<any | undefined>(UPDATE_SESSION_SUCCESS);
export const searchSession = createAction<any | undefined>(SEARCH_SESSION);
export const searchSessionSuccess = createAction<any | undefined>(SEARCH_SESSION_SUCCESS);

export const getApplicationSession = createAction<any | undefined>(GET_APPLICATION_SESSION);
export const getApplicationSessionSuccess = createAction<any | undefined>(GET_APPLICATION_SESSION_SUCCESS);
export const getApplicationSessionFailure = createAction<any | undefined>(GET_APPLICATION_SESSION_FAILURE);