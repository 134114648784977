import { ApplicationState, TApplication } from 'types/application';
import {
  CREATE_APPLICATION,
  RETRIEVE_APPLICATIONS,
  UPDATE_APPLICATION,
  DELETE_APPLICATION,
  RETRIEVE_APPLICATIONS_FAIL,
  RETRIEVE_APPLICATIONS_SUCCESS,
  CREATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_SUCCESS,
  SEARCH_APPLICATION,
  SEARCH_APPLICATION_SUCCESS,
  RETRIEVE_APPLICATION_SUCCESS,
  GET_APPLICATION_STATS,
  GET_APPLICATION_STATS_SUCCESS,
  GET_APPLICATION_STATS_FAILURE,
  GET_APPLICATION_TIMELINE,
  GET_APPLICATION_TIMELINE_FAILURE,
  GET_APPLICATION_TIMELINE_SUCCESS,
  GET_APPLICATION_CRYPTO_PROPERTIES,
  GET_APPLICATION_CRYPTO_PROPERTIES_SUCCESS,
  GET_APPLICATION_CRYPTO_PROPERTIES_FAILURE,
  UPDATE_APPLICATION_ALGO_MAP,
  UPDATE_APPLICATION_ALGO_MAP_SUCCESS,
} from '../constants/Application';

const initialState: ApplicationState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  applications: [],
  selectedApplication: {} as TApplication,
  stats: {},
  cryptoProperties: {},
  sessions: []
};

function applicationReducer(state = initialState, action) {
  const { type, payload } = action;

  console.log(action);

  switch (type) {
    case CREATE_APPLICATION:
      return { ...state, loading: true };

    case CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data?.message
      };

    case RETRIEVE_APPLICATIONS:
      return { ...state, loading: true };

    case RETRIEVE_APPLICATIONS_SUCCESS:
      return { ...state, applications: payload.data?.application, loading: false };

    case UPDATE_APPLICATION:
      console.log("Printing Applications")
      console.log(state.applications)
      return {
        ...state,
        loading: true,
        applications: state.applications.map((application: any) => {
          if (application._id === payload.data._id) {
            return {
              ...application,
              ...payload.data
            };
          } else {
            return application;
          }
        })
      };

    case UPDATE_APPLICATION_SUCCESS:
      return { ...state, loading: false, message: payload.message };

    case DELETE_APPLICATION:
      return { ...state, loading: true };

    case RETRIEVE_APPLICATIONS_FAIL:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    case SEARCH_APPLICATION:
      return { ...state, loading: true };

    case SEARCH_APPLICATION_SUCCESS:
      return { ...state, loading: false, applications: payload.data?.applications };

    case RETRIEVE_APPLICATION_SUCCESS:
      return { ...state, loading: false, selectedApplication: payload.data?.application };

    case GET_APPLICATION_STATS:
      return { ...state, loading: true };

    case GET_APPLICATION_STATS_SUCCESS:
      return { ...state, loading: false, stats: payload };

    case GET_APPLICATION_STATS_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    case GET_APPLICATION_TIMELINE:
      return { ...state, loading: true };

    case GET_APPLICATION_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        workFlow: payload.data.applicationWorkFlow
      };

    case GET_APPLICATION_TIMELINE_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    case GET_APPLICATION_CRYPTO_PROPERTIES:
      return { ...state, loading: true };

    case GET_APPLICATION_CRYPTO_PROPERTIES_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };

    case GET_APPLICATION_CRYPTO_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        cryptoProperties: payload.data?.cryptoProperties
      };

    case UPDATE_APPLICATION_ALGO_MAP:
      return { ...state, loading: true };

    case UPDATE_APPLICATION_ALGO_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data?.message
      };

    default:
      return state;
  }
}

export default applicationReducer;