import {
  CREATE_APPLICATION,
  CREATE_APPLICATION_SUCCESS,
  DELETE_APPLICATION,
  DELETE_APPLICATION_SUCCESS,
  RETRIEVE_APPLICATION,
  RETRIEVE_APPLICATIONS,
  RETRIEVE_APPLICATIONS_FAIL,
  RETRIEVE_APPLICATIONS_SUCCESS,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  SEARCH_APPLICATION_SUCCESS,
  SEARCH_APPLICATION,
  RETRIEVE_APPLICATION_SUCCESS,
  GET_APPLICATION_STATS,
  GET_APPLICATION_STATS_SUCCESS,
  GET_APPLICATION_STATS_FAILURE,
  GET_APPLICATION_TIMELINE,
  GET_APPLICATION_TIMELINE_SUCCESS,
  GET_APPLICATION_TIMELINE_FAILURE,
  GET_APPLICATION_CRYPTO_PROPERTIES,
  GET_APPLICATION_CRYPTO_PROPERTIES_SUCCESS,
  GET_APPLICATION_CRYPTO_PROPERTIES_FAILURE,
  UPDATE_APPLICATION_ALGO_MAP,
  UPDATE_APPLICATION_ALGO_MAP_SUCCESS
} from '../constants/Application';

import { createAction } from 'redux-act';

// Application
export const createApplication = createAction<any | undefined>(CREATE_APPLICATION);
export const createApplicationSuccess = createAction<any | undefined>(CREATE_APPLICATION_SUCCESS);
export const deleteApplication = createAction<any | undefined>(DELETE_APPLICATION);
export const deleteApplicationSuccess = createAction<any | undefined>(DELETE_APPLICATION_SUCCESS);
export const retrieveApplication = createAction<any | undefined>(RETRIEVE_APPLICATION);
export const retrieveApplicationSuccess = createAction<any | undefined>(RETRIEVE_APPLICATION_SUCCESS);
export const retrieveApplications = createAction<any | undefined>(RETRIEVE_APPLICATIONS);
export const retrieveApplicationsSuccess = createAction<any | undefined>(RETRIEVE_APPLICATIONS_SUCCESS);
export const retrieveApplicationsFailure = createAction<any | undefined>(RETRIEVE_APPLICATIONS_FAIL);
export const updateApplication = createAction<any | undefined>(UPDATE_APPLICATION);
export const updateApplicationSuccess = createAction<any | undefined>(UPDATE_APPLICATION_SUCCESS);
export const searchApplication = createAction<any | undefined>(SEARCH_APPLICATION);
export const searchApplicationSuccess = createAction<any | undefined>(SEARCH_APPLICATION_SUCCESS);

export const getApplicationStats = createAction<any | undefined>(GET_APPLICATION_STATS);
export const getApplicationStatsSuccess = createAction<any | undefined>(GET_APPLICATION_STATS_SUCCESS);
export const getApplicationStatsFailure = createAction<any | undefined>(GET_APPLICATION_STATS_FAILURE);

export const getApplicationTimeline = createAction<any | undefined>(GET_APPLICATION_TIMELINE);
export const getApplicationTimelineSuccess = createAction<any | undefined>(GET_APPLICATION_TIMELINE_SUCCESS);
export const getApplicationTimelineFailure = createAction<any | undefined>(GET_APPLICATION_TIMELINE_FAILURE);

export const getCryptoProperties = createAction<any | undefined>(GET_APPLICATION_CRYPTO_PROPERTIES);
export const getCryptoPropertiesSuccess = createAction<any | undefined>(GET_APPLICATION_CRYPTO_PROPERTIES_SUCCESS);
export const getCryptoPropertiesFailure = createAction<any | undefined>(GET_APPLICATION_CRYPTO_PROPERTIES_FAILURE);

export const updateApplicationAlgoMap = createAction<any | undefined>(UPDATE_APPLICATION_ALGO_MAP);
export const updateApplicationAlgoMapSuccess = createAction<any | undefined>(UPDATE_APPLICATION_ALGO_MAP_SUCCESS);