
import {
  RETRIEVE_PERMISSIONS,
  RETRIEVE_PERMISSIONS_SUCCESS,
  RETRIEVE_PERMISSIONS_FAILIURE,
  CREATE_PERMISSIONS,
  CREATE_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS,
  UPDATE_PERMISSIONS_SUCCESS,
  DELETE_PERMISSIONS,
  DELETE_PERMISSIONS_SUCCESS
} from '../constants/Permissions';

const initialState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  permissions: [],
};

function userPermissonReducer(state = initialState, action) {
  const { type, payload } = action;
  console.log(action);

  switch (type) {

    case RETRIEVE_PERMISSIONS:

      return { ...state, loading: true };


    case RETRIEVE_PERMISSIONS_SUCCESS:

      console.log(" Reducer permissions : ", payload)
      return { ...state, loading: false, permissions: payload.data?.permissions };


    case RETRIEVE_PERMISSIONS_FAILIURE:
      return { ...state, loading: false, error: true, message: payload.data?.message || '' };


    case CREATE_PERMISSIONS:
      return {
        ...state,
        loading: true,
        message: payload.data?.message
      };
    case CREATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data?.message
      };

    case UPDATE_PERMISSIONS:

      return {
        ...state,
        loading: true,
        permissions: state.permissions.map((permission: any) => {
          if (permission._id === payload.data._id) {
            console.log("EEEEEEEEEEEEE ", payload);
            return {
              ...permission,
              ...payload.data
            };
          } else {
            return permission;
          }
        })
      };

    case UPDATE_PERMISSIONS_SUCCESS:

      return {

        ...state, loading: false, message: payload.data?.message
      };

    case DELETE_PERMISSIONS:

      return {
        ...state,
        loading: true,
        rules: state.permissions.map((permission: any, index) => {
          console.log(permission._id, payload.id);
          if (permission._id === payload.id) {
            return {

              ...state.permissions.splice(index, 1)
            };
          } else {

            return permission;
          }

        })

      };

    case DELETE_PERMISSIONS_SUCCESS:
      return { ...state, loading: false, permissions: payload.data?.users };





    default:
      return state;
  }
}

export default userPermissonReducer;

